// additional category urls
const additionalCategoryUrls = {
  'rugs': 101330,
  'rugs-by-style': 101346,
  'vintage': 101336,
  'outdoor-dining-furniture': 101492,
  'outdoor-accent-tables-chairs': 101494,
  'outdoor-tabletop': 101500,
  'furniture-benches': 119614,
  'outdoor-garden-stools': 101499,
  'outdoor-planters-garden': 118835,
  'brooches-pins': 119323,
  'outdoor-storage-covers': 118832,
  'outdoor-lounge-furniture': 101493,
  'sofas-loveseats': 125631,
  'rugs-by-material': 101345,
  'furniture-ottomans': 119615,
  'dining-sets': 125636,
  'ottomans-poufs-stools': 119615,
  'lighting-lanterns': 119698,
  'scarves': 119342,
  'bar-cocktail-glasses': 119455,
  'pillows': 119258,
  'bottle-openers': 119471,
  'dining-chairs-and-benches': 125637,
  'furniture-bookcases': 119612,
  'jewelry': 119249,
  'bracelets': 119318,
  'champagne-flutes': 119466,
  'wreaths-garlands': 119301,
  'furniture-console-tables': 119610,
  'tablecloths-runners': 119483,
  'bed-bath-shams': 121695,
  'pillows-decor-topiaries-trees': 119300,
  'outdoor-statuary-fountains': 101501,
  'lighting-pendants': 119682,
  '-tag-res': 119661,
  'pillows-decor-decorative-kitchenware': 119284,
  'coverlets': 121708,
  'bookcases-tag-res': 119612,
  'robes-slippers-sleepwear': 121689,
  'candle-holders': 119289,
  'pillows-decor-coasters': 119473,
}

export const targetCategoryUrls = {
  'art-drawings': 101413,
  'art-mirrors-art': 101349,
  'art-mirrors-floor-mirrors': 101418,
  'art-mirrors-maps-flags-posters': 101416,
  'art-mirrors-mirrors': 101351,
  'art-mirrors-mixed-media': 101414,
  'art-mirrors-paintings': 101412,
  'art-mirrors-photography': 101411,
  'art-mirrors-vintage-reproductions': 101415,
  'art-mirrors-wall-mirrors': 101417,
  'art-mirrors-wallpaper': 119242,
  'art-mirrors': 101332,
  'bags-accessories': 119250,
  'bed-bath-accessories': 121692,
  'bed-bath-bath': 121683,
  'bed-bath-beach-towels': 121720,
  'bed-bath-bedding-basics': 121686,
  'bed-bath-bedding': 121682,
  'bed-bath-blankets-quilts': 121687,
  'bed-bath-duvet-covers': 121684,
  'bed-bath-mats': 121691,
  'bed-bath-sheets-pillows': 121685,
  'bed-bath-shower-curtains': 121690,
  'bed-bath-towels': 121688,
  'bed-bath-waste-baskets': 121722,
  'bed-bath': 121681,
  'bistro-tables': 119676,
  'brands-justina-blakeney-amber-lewis-more': 20220712,
  'dash-albert': 20220702,
  'decor-baskets-organization': 119238,
  'decor-books-bookends': 119248,
  'decor-candles-diffusers': 119247,
  'decor-decorative-accents': 119236,
  'decor-desk-stationery': 119251,
  'decor-floral-greenery': 119237,
  'decor-games': 119253,
  'decor-gifts-accessories': 119246,
  'decor-home-accents': 119216,
  'decor-picture-frames': 119235,
  'decor-pillows-throws': 119239,
  'decor-pillows': 119518,
  'decor-planters-garden': 119257,
  'decor-shelving-brackets': 119244,
  'decor-throws': 119529,
  'decor-trays-boxes': 119234,
  'decor-vases-jars': 119233,
  'decor': 119215,
  'furniture-accent-chairs': 119650,
  'furniture-arm-chairs': 119678,
  'furniture-armoires': 119702,
  'furniture-bar-tables': 119677,
  'furniture-barstools-counter-stools': 119622,
  'furniture-bedroom': 119601,
  'furniture-beds': 119628,
  'furniture-benches-banquettes': 119625,
  'furniture-buffets-sideboards': 119623,
  'furniture-cabinets-hutches': 119624,
  'furniture-cabinets': 119613,
  'furniture-center-tables': 119713,
  'furniture-chairs': 119607,
  'furniture-chaise-lounges-daybeds': 119606,
  'furniture-chaise-lounges': 119648,
  'furniture-club-chairs': 119652,
  'furniture-coat-racks-umbrella-stands': 119637,
  'furniture-coat-racks': 119715,
  'furniture-coffee-tables': 119608,
  'furniture-consoles-center-tables': 119636,
  'furniture-daybeds': 119649,
  'furniture-desk-chairs': 119618,
  'furniture-desks': 119617,
  'furniture-dining-chair-sets': 119681,
  'furniture-dining-chairs': 119621,
  'furniture-dining-room': 119600,
  'furniture-dining-tables': 119674,
  'furniture-dressers-armoires': 119631,
  'furniture-dressers': 119701,
  'furniture-end-of-bed': 119634,
  'furniture-entry': 119602,
  'furniture-four-poster-canopy-beds': 119689,
  'furniture-garden-stools': 119666,
  'furniture-hammocks-porch-swings': 119642,
  'furniture-headboards': 119629,
  'furniture-living-room': 119588,
  'furniture-nesting-tables': 119657,
  'furniture-nightstands': 119630,
  'furniture-office': 119599,
  'furniture-other-stools': 119668,
  'furniture-outdoor-dining-furniture': 119640,
  'furniture-outdoor-lounge-furniture': 119639,
  'furniture-panel-beds': 119685,
  'furniture-pedestals-plant-stands': 119638,
  'furniture-plant-stands': 119720,
  'furniture-platform-beds': 119690,
  'furniture-poufs': 119665,
  'furniture-recliners-gliders-swivel-chairs': 119653,
  'furniture-room-screens': 119616,
  'furniture-sectionals': 119605,
  'furniture-settees': 119646,
  'furniture-side-chairs': 119679,
  'furniture-side-tables': 119609,
  'furniture-sideboards-media-storage': 119611,
  'furniture-sleeper-sofas': 119647,
  'furniture-sofas-settees': 119604,
  'furniture-sofas': 127620,
  'furniture-standard-benches': 119709,
  'furniture-standard-side-tables': 119656,
  'furniture-storage-benches': 119708,
  'furniture-storage-furniture-benches': 119635,
  'furniture-storage-furniture': 119707,
  'furniture-tray-tables': 119658,
  'furniture-trunks': 119706,
  'furniture-umbrella-stands': 119716,
  'furniture-vanities-dressing-tables': 119703,
  'furniture-vanities-vanity-stools': 119632,
  'furniture-vanity-stools': 119704,
  'furniture-wingback-beds': 119686,
  'furniture-wingback-chairs': 119651,
  'furniture': 119587,
  'kitchen-islands-carts': 119627,
  'lighting-accessories': 119673,
  'lighting-bath-lights': 119717,
  'lighting-ceiling-lights-fans': 119670,
  'lighting-chandeliers': 119680,
  'lighting-finials': 119722,
  'lighting-floor-lamps': 119711,
  'lighting-flush-mounts': 119683,
  'lighting-picture-lights': 119718,
  'lighting-sconces': 119714,
  'lighting-shades': 119721,
  'lighting-table-floor-lamps': 119671,
  'lighting-table-lamps': 119710,
  'lighting-wall-lighting': 119672,
  'lighting': 119667,
  'outdoor-chairs': 125633,
  'outdoor-doormats': 101505,
  'outdoor-furniture': 101370,
  'outdoor-lighting': 119675,
  'outdoor-ottomans': 125635,
  'outdoor-outdoor-accents': 101371,
  'outdoor-outdoor-lighting': 101373,
  'outdoor-porch-swings': 118837,
  'outdoor-poufs': 125634,
  'outdoor-rugs-doormats': 101372,
  'outdoor-umbrellas-stands': 118838,
  'outdoor': 101338,
  'pillows-decor-beverage-dispensers': 119427,
  'pillows-decor-candlesticks-candelabras': 119292,
  'pillows-decor-decorative-bowls-centerpieces': 119283,
  'pillows-decor-decorative-jars-canisters': 119277,
  'pillows-decor-diffusers-sprays': 119308,
  'pillows-decor-grooming-wellness': 119334,
  'pillows-decor-indoor-games': 119364,
  'pillows-decor-indoor-planters-urns': 119278,
  'pillows-decor-napkin-rings-holders': 119485,
  'pillows-decor-pitchers-carafes': 119428,
  'pillows-decor-tea-cups-saucers': 119470,
  'pillows-decor-travel-accessories': 119341,
  'pillows-decor-travel': 119327,
  'rugs-by-material-hides-sheepskin': 101401,
  'rugs-by-material-natural-fiber': 101400,
  'rugs-by-material-wool': 101399,
  'rugs-by-style-contemporary': 101403,
  'rugs-by-style-global': 101404,
  'rugs-by-style-traditional': 101402,
  'rugs-rug-pads': 101397,
  'tabletop-bar-carts': 119626,
  'tabletop-bar-tools-accessories': 119268,
  'tabletop-dinnerware': 119265,
  'tabletop-drinkware': 119267,
  'tabletop-flatware': 119266,
  'tabletop-serveware': 119264,
  'tabletop-table-kitchen-linens': 119269,
  'tabletop': 119263,
  'visual-comfort': 20220529,
  // Add newly created Category Urls to the targetCategory Urls here
  ...additionalCategoryUrls,
}
