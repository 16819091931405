import {
  object,
  string,
  array,
  type Checker,
  literal,
  optional,
  union,
  dict,
  constraint,
} from '@recoiljs/refine'
import axios from 'axios'
import { withSentryAssertion } from '@/helpers/withSentryAssertion'
import { assetionOrSilentNull } from '@/helpers/assertionOrSilentNull'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { CDN_API } from '@/services/Configuration'
import type {
  LongformProseContent,
  SeoFooterEntryWithImage,
  SeoFooterEntryWithoutImage,
  SeoFooterResponse,
} from './types'
import { targetCategoryUrls } from './data/targetCategoryUrls'

export const seoFooterEntryWithImageChecker: Checker<SeoFooterEntryWithImage> = object({
  phrase: string(),
  imageUrl: string(),
  url: string(),
  renderableText: string(),
})

export const seoFooterEntryWithoutImageChecker: Checker<SeoFooterEntryWithoutImage> = object({
  phrase: string(),
  url: string(),
  renderableText: string(),
})

export const seoFooterResponseChecker: Checker<SeoFooterResponse> = object({
  item: object({
    metaDescription: optional(union(string(), literal(null))),
    metaTitle: optional(union(string(), literal(null))),
    description: union(string(), literal(null)),
    name: union(string(), literal(null)),
    material: union(string(), literal(null)),
    size: union(string(), literal(null)),
    color: union(string(), literal(null)),
    finish: union(string(), literal(null)),
    letter: union(string(), literal(null)),
  }),
  roomSuitabilityAndStyling: union(string(), literal(null)),
  knownTags: dict(string()),
  relatedSearches: array(seoFooterEntryWithImageChecker),
  goesBestWith: array(seoFooterEntryWithoutImageChecker),
  supplementary: array(seoFooterEntryWithoutImageChecker),
})
const assertSeoFooterResponse = assetionOrSilentNull(withSentryAssertion([seoFooterResponseChecker, 'SeoFooterResponse']))

export const candidateUrls = Object.keys(targetCategoryUrls)
export const longformProseContentChecker: Checker<LongformProseContent> = object({
  title: string(),
  blocks: array(object({
    heading: string(),
    content: string(),
    link: object({
      url: constraint(string(), (s) => (candidateUrls.includes(s) ? true : [false, 'must EXACTLY match a candidate url slug'])),
      text: string(),
    }),
  })),
})
export const assertLongformProseContent = assetionOrSilentNull(withSentryAssertion([longformProseContentChecker, 'LongformProseContent']))

export const getSeoPortions = async (sku: string) => {
  const response = await axios.get<SeoFooterResponse>(`${CDN_API}/ssr-api/seo-footer/${sku}`)
  return response.data === null ? null : assertSeoFooterResponse(response.data)
}
export const getLongformProseContent = async (
  browseUrlName: string,
): Promise<LongformProseContent | null> => {
  const queryWithHyphens = browseUrlName.replace(/ /g, '-').toLowerCase().replace(/[^a-z0-9-]/g, '').replace(/-+/g, '-')
  const [{ wordBank }] = await allPromisesWithRetries(() => [import('./data/browseSlugsWordBank')])
  if (!wordBank.includes(queryWithHyphens)) {
    return null
  }
  try {
    const response = await axios.get<LongformProseContent>(`${CDN_API}/ssr-api/seo-content/${queryWithHyphens}.json`)
    return response.data === null ? null : assertLongformProseContent(response.data)
  } catch (e) {
    console.error(e)
    return null
  }
}
